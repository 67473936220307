import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleEventDatePipe } from './custom-pipe/sale-event-date.pipe';
import { NextTabDirective } from './custom-directive/next-tab.directive';
import { SaleEventDateRangePipe } from './custom-pipe/sale-event-date-range.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SaleEventDateRangePipe, SaleEventDatePipe, NextTabDirective],
  exports: [SaleEventDatePipe, SaleEventDateRangePipe, NextTabDirective]
})
export class PurpleProjectWidgetsModule { }
