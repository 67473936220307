import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription, lastValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { ReferralService } from 'src/app/referral/referral.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PRPVSaleEventFull, PurpleApiResponseStatus, SaleEventPreview, SaleEventPreviewStatus, UserNextSaleEventBookingItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/default/environment';
import { PrivateInviteFriendsDrawerComponent } from '../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PrivateInviteFriendsModalComponent } from '../modals/private-invite-friends-modal/private-invite-friends-modal.component';
import { InviteFriendsModalComponent } from '../modals/invite-friends-modal/invite-friends-modal.component';
import { InviteFriendsDrawerComponent } from '../drawers/invite-friends-drawer/invite-friends-drawer.component';
import { PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { AccountNeedVerificationService } from '../components/account-need-verification-banner/account-need-verification.service';

@Component({
  selector: 'app-private-sale-events-preview',
  templateUrl: './private-sale-events-preview.component.html',
  styleUrls: ['./private-sale-events-preview.component.scss']
})
export class PrivateSaleEventsPreviewComponent implements OnInit, OnDestroy {


  constructor(private route: ActivatedRoute, private seSvc: SaleEventService, public tsvc: AppTranslationService, private loaderSvc: PurpleLoaderService, private breakpointObserver: BreakpointObserver,
    private bookSvc: BookingService, private drawerSvc: NzDrawerService, private tranPipe: PurpleTranslationPipe, private layoutSvc: LayoutService, private refSvc: ReferralService, private modalSvc: NzModalService,    private bannerSvc: AccountNeedVerificationService) {
    this.showName = environment.SHOW_EVENTS_SALE_NAME;

    this.bannerSvc.showBanner();
  }
  saleEvents: SaleEventPreview[] = [];
  subtitleText: string = this.tranPipe.transform("sale_events_preview_subtitle", "Ecco a te le prossime private sales <span class='dot'>!</span>")
  options: AnimationOptions = {
    path: '/assets/loaders/' + environment.COMPANY_ABB + '/no-private-sales.json',
  };
  showName: boolean = true;
  isMobile: boolean = true;
  //carouselSlides: SaleEventCarouselSlideItem[] = [];
  subs: Subscription = new Subscription();

  pageTitle: pageTitle = {
    title: this.tranPipe.transform('sale_events_preview_title', 'Ciao', []),
    titleTranslationKey: 'sale_events_preview_title',
    subtitle: undefined
    /* {
      subtitle: this.tranPipe.transform('sale_events_preview_subtitle','Ecco a te le prossime private sales', []),
      subtitleTranslationKey: 'sale_events_preview_subtitle'
    } */
  }

  async ngOnInit() {

    this.loaderSvc.addRequest("general");
    await this.refSvc.reedemReferralCode();

    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params)
      });

    const resp = await lastValueFrom(this.bookSvc.getCanBookSaleEvents(this.tsvc.currentLanguage.value));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.saleEvents = resp.data!
    } else {
      this.saleEvents = []
    }

    /* var res = await lastValueFrom(this.bookSvc.getHomepageCarouselSlides(this.tsvc.currentLanguage.value, {
      carouselId: "homepage_banner"
    }));

    if (res.status == PurpleApiResponseStatus.Success) {
      this.carouselSlides = res.data ?? [];
    } else {
      this.carouselSlides = [];
    } */


    this.loaderSvc.removeRequest("general");
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.bannerSvc.hideBanner();
  }

  InviteFriend(se: SaleEventPreview) {
    console.log("Sale Event: ", se)
    if (this.isMobile) {
      this.showInviteFriendsDrawer(se);
    } else {
      this.showInviteFriendsModal(se);
    }
  }

  getButtonText(status: SaleEventPreviewStatus): { text: string, key: string } {
    switch (status) {
      case "canBook":
        return { text: this.tranPipe.transform('sale_events_preview_book_now', 'Prenota ora', []), key: "sale_events_preview_book_now" }
      case "booked":
        return { text: this.tranPipe.transform('sale_events_preview_booked', 'Prenotato', []), key: "sale_events_preview_booked" }
      case "preview":
        return { text: this.tranPipe.transform('sale_events_preview_preview', 'Abbonati', []), key: "sale_events_preview_preview" }
      case "coomingSoon":
        return { text: this.tranPipe.transform('sale_events_preview_coming_soon', 'Vedi', []), key: "sale_events_preview_coming_soon" }
      default:
        return { text: this.tranPipe.transform('sale_events_preview_error', 'Errore', []), key: "sale_events_preview_error" }
    }
  }


  showInviteFriendsModal(se: SaleEventPreview) {
    if (se.saleEvent.isPrivateInviteCounter) {
      this.modalSvc.create<PrivateInviteFriendsModalComponent, { saleEventId: string, saleEventName: string, saleEventPhone: string, saleEventEmail: string }>({
        nzContent: PrivateInviteFriendsModalComponent,
        nzTitle: undefined,
        nzWidth: '1000px',
        nzCloseIcon: undefined,
        nzClosable: false,
        nzCentered: true,
        nzClassName: "private-invite-friends-modal",
        nzData: {
          saleEventId: se.saleEvent.saleEventId,
          saleEventEmail: se.saleEvent.saleEventEmail,
          saleEventPhone: se.saleEvent.saleEventPhone,
          saleEventName: se.saleEvent.saleEventName
        },
        nzFooter: null,
        nzMaskClosable: false,
      });
    } else {
      if (se.isBooked) {
        this.modalSvc.create<InviteFriendsModalComponent, { book?: UserNextSaleEventBookingItem | undefined, saleEvent: PRPVSaleEventFull }>({
          nzContent: InviteFriendsModalComponent,
          nzTitle: undefined,
          nzWidth: '1000px',
          nzCloseIcon: undefined,
          nzClosable: false,
          nzCentered: true,
          nzClassName: "invite-friends-modal",
          nzData:
          {
            book: {
              address: se.saleEvent.addressFull!,
              addressExternalId: se.saleEvent.addressExternalId!,
              addressLat: se.saleEvent.addressLat!,
              addressLon: se.saleEvent.addressLon!,
              isPrivateInviteCounter: se.saleEvent.isPrivateInviteCounter,
              saleEventCode: se.saleEvent.saleEventCode,
              saleEventEmail: se.saleEvent.saleEventEmail,
              saleEventId: se.saleEvent.saleEventId,
              saleEventName: se.saleEvent.saleEventName,
              saleEventPhone: se.saleEvent.saleEventPhone,
              userCanInvite: se.canInvite,
              userCanDelete: true, 
              slot: {
                bookingId: se.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: se.userSlotBooked!.roleId,
                roleName: se.userSlotBooked!.roleName,
                slotEndDate: se.userSlotBooked!.slotEndDate,
                slotStartDate: se.userSlotBooked!.slotStartDate,
                slotGmtInfo: se.userSlotBooked!.slotGmtInfo,
              }
            },
            saleEvent: se.saleEvent
          },
          nzFooter: null,
          nzMaskClosable: false,
      });
    }
  }
}

checkCanInvite(se: SaleEventPreview): boolean{
  if (se.saleEvent.isPrivateInviteCounter) {
    return se.canInvite;
  } else {
    return se.isBooked && se.canInvite;
  }
}

showInviteFriendsDrawer(se: SaleEventPreview) {
  if (se.saleEvent.isPrivateInviteCounter) {
    this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: PrivateInviteFriendsDrawerComponent,
      nzClosable: false,
      nzWrapClassName: "private-invite-friends",
      nzFooter: undefined,
      nzPlacement: 'bottom',
      nzContentParams: {
        saleEventId: se.saleEvent.saleEventId
      }
    });
  } else {
    if (se.isBooked) {
      this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: InviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          book: {
            address: se.saleEvent.addressFull!,
            addressExternalId: se.saleEvent.addressExternalId!,
            addressLat: se.saleEvent.addressLat!,
            addressLon: se.saleEvent.addressLon!,
            isPrivateInviteCounter: se.saleEvent.isPrivateInviteCounter,
            saleEventCode: se.saleEvent.saleEventCode,
            saleEventEmail: se.saleEvent.saleEventEmail,
            saleEventId: se.saleEvent.saleEventId,
            saleEventName: se.saleEvent.saleEventName,
            saleEventPhone: se.saleEvent.saleEventPhone,
            userCanInvite: se.canInvite,
            userCanDelete: true, //TODO
            slot: {
              bookingId: se.userSlotBooked!.saleEventSlotBookingId,
              requireAssistance: false,
              roleId: se.userSlotBooked!.roleId,
              roleName: se.userSlotBooked!.roleName,
              slotEndDate: se.userSlotBooked!.slotEndDate,
              slotStartDate: se.userSlotBooked!.slotStartDate,
              slotGmtInfo: se.userSlotBooked!.slotGmtInfo,
            }
          }
        }
      }).afterClose.subscribe(() => {
        this.layoutSvc.qrCodeOpen.next(false)
      })
    }
  }
}


}
