import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ReferralService } from 'src/app/referral/referral.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, GmtInfo, PRPVPolicyFull, SaleEventBookedSlot, SaleEventDetail, SaleEventDetailSlot, SaleEventDetailSlotItem, SaleEventUserStatusDetail, UserExtendedReferral } from 'src/core-modules/sdk/api';
import { BookSlotDrawerComponent } from '../drawers/book-slot-drawer/book-slot-drawer.component';
import { BookSlotModalComponent } from '../modals/book-slot-modal/book-slot-modal.component';
import { PrivateInviteFriendsDrawerComponent } from '../drawers/private-invite-friends-drawer/private-invite-friends-drawer.component';
import { InviteFriendsDrawerComponent } from '../drawers/invite-friends-drawer/invite-friends-drawer.component';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { DatePipe } from '@angular/common';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { pageTitle } from 'src/app/components/purple-sales-page-title/purple-sales-page-title.component';
import { AccountNeedVerificationService } from '../components/account-need-verification-banner/account-need-verification.service';
import { PurpleSalesNotificationService } from 'src/app/components/purple-sales-notification.service';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-private-sale-event-detail',
  templateUrl: './private-sale-event-detail.component.html',
  styleUrls: ['./private-sale-event-detail.component.scss']
})
export class PrivateSaleEventDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private refSvc: ReferralService, private datePipe: DatePipe,
    private bookSvc: BookingService, private accRouter: ActivatedRoute, public modalSvc: NzModalService, private drawerSvc: NzDrawerService, private router: Router,
    private tranPipe: PurpleTranslationPipe, private bannerSvc: AccountNeedVerificationService, private loaderSvc: PurpleLoaderService, private apiProxySvc: PurpleApiProxyService,
    private psNotSvc: PurpleSalesNotificationService, private breakSvc: BreakpointsService, private authSvc: AuthenticationService) {

  }
  slidesForView: number = 3;
  tooShort: boolean = false;
  saleEvent: number = 3;
  saleEventDetail: SaleEventDetail | undefined;
  currentDay: SaleEventDetailSlot | undefined;
  classContainerHtml: HTMLElement | undefined;
  numberOfDays: BehaviorSubject<number> = new BehaviorSubject<number>(5);

  dateCalendarDesktopSliderConfig: NguCarouselConfig | undefined;

  showCalendar: boolean = true;

  pageTitle: pageTitle = {
    title: this.tranPipe.transform('private_sale_detail_title', 'Prenota il tuo slot!', []),
    titleTranslationKey: 'private_sale_detail_title',
    subtitle: undefined
  }

  @ViewChild('bookingCalendarDaysContainer') daysContainer: ElementRef | undefined;
  @ViewChild('dayCarousel') dayCarousel!: NguCarousel<any>;
  /* @ViewChild('daySlider', { static: false }) swiper?: SwiperComponent; */

  invitedFriends: Array<UserExtendedReferral> = []

  async ngOnInit() {
    //console.log("SNAP: ", this.accRouter.children[0].snapshot)
    this.bannerSvc.showBanner();
    var sec: string | undefined = this.accRouter.children[0].snapshot.params['saleEventCode'];

    this.route.queryParams.subscribe(params => {
      this.seSvc.getSaleEventParameters(params);
    });

    this.numberOfDays.subscribe((val) => {
      this.dateCalendarDesktopSliderConfig = {
        grid: { xs: val, sm: val, md: val, lg: val, xl: val, all: 0 },
        slide: 1,
        speed: 100,
        point: {
          visible: true,
        },
        load: 1,
        loop: false,
        touch: false,
      };
    });

    if (sec != undefined) {
      await this.refSvc.reedemReferralCode();
      await this.getSaleEventDetail(sec, false)
    }
  }

  ngOnDestroy(): void {
    this.bannerSvc.hideBanner();
  }

  showInviteFriendsDrawer() {
    if (this.saleEventDetail!.saleEvent.isPrivateInviteCounter) {
      this.drawerSvc.create<PrivateInviteFriendsDrawerComponent, { value: string }, string>({
        nzTitle: undefined,
        nzContent: PrivateInviteFriendsDrawerComponent,
        nzClosable: false,
        nzWrapClassName: "private-invite-friends",
        nzFooter: undefined,
        nzPlacement: 'bottom',
        nzContentParams: {
          saleEventId: this.saleEventDetail?.saleEvent.saleEventId
        }
      });
    } else {
      if ((this.saleEventDetail!.userSlotBooked ?? undefined) != undefined) {
        this.drawerSvc.create<InviteFriendsDrawerComponent, { value: string }, string>({
          nzTitle: undefined,
          nzContent: InviteFriendsDrawerComponent,
          nzClosable: false,
          nzWrapClassName: "invite-friends",
          nzFooter: undefined,
          nzPlacement: 'bottom',
          nzContentParams: {
            book: {
              address: this.saleEventDetail!.saleEvent.addressFull!,
              addressExternalId: this.saleEventDetail!.saleEvent.addressExternalId!,
              addressLat: this.saleEventDetail!.saleEvent.addressLat!,
              addressLon: this.saleEventDetail!.saleEvent.addressLon!,
              isPrivateInviteCounter: this.saleEventDetail!.saleEvent.isPrivateInviteCounter,
              saleEventCode: this.saleEventDetail!.saleEvent.saleEventCode,
              saleEventEmail: this.saleEventDetail!.saleEvent.saleEventEmail,
              saleEventId: this.saleEventDetail!.saleEvent.saleEventId,
              saleEventName: this.saleEventDetail!.saleEvent.saleEventName,
              saleEventPhone: this.saleEventDetail!.saleEvent.saleEventPhone,
              userCanInvite: this.saleEventDetail!.canInvite,
              userCanDelete: true, //TODO
              slot: {
                bookingId: this.saleEventDetail!.userSlotBooked!.saleEventSlotBookingId,
                requireAssistance: false,
                roleId: this.saleEventDetail!.userSlotBooked!.roleId,
                roleName: this.saleEventDetail!.userSlotBooked!.roleName,
                slotEndDate: this.saleEventDetail!.userSlotBooked!.slotEndDate,
                slotStartDate: this.saleEventDetail!.userSlotBooked!.slotStartDate,
                slotGmtInfo: this.saleEventDetail!.userSlotBooked!.slotGmtInfo,
              }
            }
          }
        });
      }
    }
  }

  async getSaleEventDetail(saleEventCode: string, refreshCalendar: boolean) {

    await this.apiProxySvc.makeRequestErrorFunction<SaleEventDetail>(() => this.bookSvc.getSaleEventDetail(this.tsvc.currentLanguage.value, {
      saleEventCode: saleEventCode
    }), true, "general", 200, (res: PurpleApiMakeRequestResponse<SaleEventDetail>) => {
      this.psNotSvc.showMotSimpleNotification(
        this.tranPipe.transform('sale_event_detail_info_error_title', 'Qualcosa è andato storto', []),
        this.tranPipe.transform('sale_event_detail_info_error_subtitle', 'Impossibile recuperare le informazioni di questa private sale', []),
        this.tranPipe.transform('sale_event_detail_info_error_button', 'Torna all\'elenco', []),
        () => {
          this.router.navigate([this.tsvc.currentLanguage.value, 'event'])
        }, 'modal', true, undefined, undefined, false, false, false, false)
    }, async (res: PurpleApiMakeRequestResponse<SaleEventDetail>) => {
      this.saleEventDetail = res.data!
      this.numberOfDays.next(this.saleEventDetail.daySlots.length > 5 ? 5 : this.saleEventDetail.daySlots.length);
      this.seSvc.currentSaleEvent.next(this.saleEventDetail.saleEvent);
      this.currentDay = this.saleEventDetail.daySlots[this.saleEventDetail.currentSlide];

      if (refreshCalendar) {
        setTimeout(() => {
          this.showCalendar = true;
          this.loaderSvc.removeRequest('general', 200);
        }, 50);
      }

      if (this.saleEventDetail.currentSlide > 0) {
        setTimeout(() => {

          const w = Math.floor(this.saleEventDetail!.currentSlide / this.numberOfDays.value);

          this.dayCarousel.moveTo(w)
          const tb = document.getElementsByClassName('purple-sales-layout-main-container-internal')
          if (tb != undefined) {
            this.classContainerHtml = tb[0] as HTMLElement;
          }
        }, 150);
      }

      if (this.saleEventDetail.saleEventUserStatus.bookingClosed) {
        const refs = await lastValueFrom(
          this.bookSvc.getUserFreeSaleEventReferral(this.tsvc.currentLanguage.value, {
            saleEventId: this.saleEventDetail.saleEvent.saleEventId
          }));

        this.invitedFriends = [...refs.data?.redeemReferralFriends ?? [], ...refs.data?.pendingReferralUsers ?? []].sort((a, b) => a.firstName.localeCompare(b.firstName))
      }

    })
  }

  ngAfterViewInit(): void {
    this.scrollToCurrentElement(200);
  }

  scrollToCurrentElement(delay: number) {
    const currentElements = document.getElementsByClassName('booked-mobile');
    setTimeout(() => {
      if (currentElements.length > 0) {
        currentElements[0].scrollIntoView({ behavior: 'smooth', block: "center" });
      }
    }, delay);
  }

  panelActive(slot: SaleEventDetailSlot): boolean {
    return slot.slots.findIndex(m => m.bookedByUser) != -1;
  }

  checkIfSlotIsFull(slot: SaleEventDetailSlotItem): boolean {
    return (!slot.isMixedRoleEnabled && slot.slotAvailableCapacity == 0) ||
      (slot.isMixedRoleEnabled && slot.maxMixedRoleAttendees != -1 && slot.maxMixedRoleAttendees == slot.currentSlotRoleAttendees);
  }

  scrollTo = () => {
    //el.scrollIntoView({ behavior: 'smooth' });
    (this.daysContainer!.nativeElement as HTMLElement).scrollIntoView({ behavior: 'smooth' })

  }

  setCurrentDay(day: SaleEventDetailSlot) {
    this.currentDay = day;
  }


  isSelectedDay(item: string) {
    return this.currentDay?.slotDayDate == item;
  }

  showSaleClosedBookInfo(status: SaleEventUserStatusDetail, bookedSlot: SaleEventBookedSlot | undefined): boolean {
    if ((status.showBookingDetail && bookedSlot != undefined) || (status.showInvitedFriends && this.invitedFriends.length > 0)) {
      return true;
    }

    return false;
  }

  async showBookingModal(slot: SaleEventDetailSlotItem, dayGmt: GmtInfo) {
    //console.log("SHOW BOOKING MODAL")    
    if (!slot.bookedByUser && slot.canBookSlot &&
      ((!slot.isMixedRoleEnabled && (slot.slotAvailableCapacity == -1 || slot.slotAvailableCapacity > 0)) ||
        (slot.isMixedRoleEnabled && (slot.maxMixedRoleAttendees == -1 || slot.maxMixedRoleAttendees > slot.currentSlotRoleAttendees)))) {
      this.seSvc.currentSaleEventUtc.next(dayGmt);
      //const idx = this.swiper?.swiperRef.activeIndex;
      //console.log("SHOW BOOKING MODAL BEFORE CREATE")

      const check = await this.authSvc.openVerifyAccountModal(false);
      if (check) {
        this.modalSvc.create<BookSlotModalComponent, { slot: SaleEventDetailSlotItem | undefined, policies: PRPVPolicyFull[], bookedSlot: SaleEventBookedSlot | undefined, saleEventName: string | undefined, canInvite?: boolean }>({
          nzContent: BookSlotModalComponent,
          nzTitle: undefined,
          nzWidth: '1000px',
          nzCloseIcon: undefined,
          nzClosable: false,
          nzCentered: true,
          nzClassName: "book-slot-modal",
          nzData: {
            slot: slot,
            bookedSlot: this.saleEventDetail?.userSlotBooked,
            saleEventName: this.saleEventDetail?.saleEvent.saleEventName,
            policies: this.saleEventDetail?.policies ?? [],
            canInvite: this.saleEventDetail?.canInvite
          },
          nzFooter: null,
          nzMaskClosable: false,
        }).afterClose.subscribe(async (reload: boolean | undefined) => {
          if ((reload ?? false) == true) {
            this.loaderSvc.addRequest('general');
            this.showCalendar = false;
            await this.getSaleEventDetail(this.seSvc.currentSaleEvent.value?.saleEventCode!, true);
          }
        });
      }
    } else {
      if (slot.containsMembershipRoles && !slot.canBookSlot) {
        this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership-detail'], { queryParams: { membership: slot.roleName.toLowerCase().replace("membership_", "") } })

        //event/membership-detail?membership=
      }
    }

  }

  async showBookingDrawer(slot: SaleEventDetailSlotItem, dayGmt: GmtInfo) {
    console.log("SLOT: ", slot)
    if (!slot.bookedByUser && slot.canBookSlot &&
      ((!slot.isMixedRoleEnabled && (slot.slotAvailableCapacity == -1 || slot.slotAvailableCapacity > 0)) ||
        (slot.isMixedRoleEnabled && (slot.maxMixedRoleAttendees == -1 || slot.maxMixedRoleAttendees > slot.currentSlotRoleAttendees)))) {

      const check = await this.authSvc.openVerifyAccountModal(false);
      if (check) {
        this.seSvc.currentSaleEventUtc.next(dayGmt);
        this.drawerSvc.create<BookSlotDrawerComponent, boolean | undefined>({
          nzTitle: undefined,
          nzContent: BookSlotDrawerComponent,
          nzClosable: false,
          nzWrapClassName: "book-slot",
          nzContentParams: {
            slot: slot,
            bookedSlot: this.saleEventDetail?.userSlotBooked,
            policies: this.saleEventDetail?.policies,
            minHeight: this.breakSvc.getDrawerHeight(550).strEq('auto') ? "550px" : this.breakSvc.deviceHeight + "px"
          },
          nzFooter: undefined,
          nzPlacement: 'bottom',
          nzHeight: this.breakSvc.getDrawerHeight(550),
        }).afterClose.subscribe(async (reload: boolean | undefined) => {
          if ((reload ?? false) == true) {
            this.loaderSvc.addRequest('general');
            this.showCalendar = false;
            this.scrollToCurrentElement(5);
            await this.getSaleEventDetail(this.seSvc.currentSaleEvent.value?.saleEventCode!, true)
          }
        });
      }
    } else {
      if (slot.containsMembershipRoles && !slot.canBookSlot) {
        this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'membership-detail'], { queryParams: { membership: slot.roleName.toLowerCase().replace("membership_", "") } })

        //event/membership-detail?membership=
      }
    }

  }

  checkCanInvite(): boolean {
    if (this.saleEventDetail!.saleEvent.isPrivateInviteCounter) {
      return this.saleEventDetail!.canInvite;
    } else {
      return ((this.saleEventDetail!.userSlotBooked ?? undefined) != undefined) && this.saleEventDetail!.canInvite;
    }
  }

  unbook(isMobile: boolean) {
    if (this.saleEventDetail?.userSlotBooked != undefined && !this.saleEventDetail.userSlotBooked.pastSlot) {

      this.modalSvc.create(
        {
          nzTitle: isMobile ? this.tranPipe.transform("modal_unbook_slot_title_mobile", "Cancella prenotazione") : this.tranPipe.transform("modal_unbook_slot_title", "Vuoi davvero cancellare la prenotazione?"),
          nzContent: this.tranPipe.transform("modal_unbook_slot_subtitle", "Sei sicuro di voler cancellare la prenotazione <br> \"<b>{2}</b>\" <b>{0} - {1}</b>",
            [this.datePipe.transform(this.saleEventDetail.userSlotBooked.slotStartDate, 'shortTime') ?? "", this.datePipe.transform(this.saleEventDetail.userSlotBooked.slotEndDate, 'shortTime') ?? "", this.saleEventDetail.saleEvent.saleEventName]),
          nzWidth: isMobile ? '80%' : '600px',
          nzClassName: 'purple-sales-simple-modal' + (isMobile ? ' purple-sales-simple-modal-mobile' : ''),
          nzCentered: isMobile ? true : false,
          nzClosable: true,
          nzMaskClosable: true,
          nzOkText: this.tranPipe.transform("modal_unbook_slot_delete_button", "Elimina"),
          nzOnOk: async () => {
            await this.apiProxySvc.makeRequestErrorFunction<void>(() => this.bookSvc.unBookAllNextSlot(this.tsvc.currentLanguage.value, {
              saleEventId: this.saleEventDetail!.saleEvent.saleEventId,
              host: window.location.hostname
            }), false, "general", 500, (res: PurpleApiMakeRequestResponse<void>) => {
              this.psNotSvc.showMotSimpleNotification(
                this.tranPipe.transform('sale_event_detail_unbook_error_title', 'Qualcosa è andato storto', []),
                res.message,
                this.tranPipe.transform('sale_event_detail_unbook_error_button', 'Torna all\'elenco', []),
                undefined, 'modal', true, undefined, undefined, false, false, false, false)
            }, (res: PurpleApiMakeRequestResponse<void>) => {
              this.psNotSvc.showMotSimpleNotification(
                this.tranPipe.transform('sale_event_detail_unbook_success_title', 'Prenotazione cancellata', []),
                this.tranPipe.transform("sale_event_detail_unbook_success_message", "La prenotazione è stata cancellata con successo"),
                this.tranPipe.transform('sale_event_detail_unbook_success_button', 'Ok', []),
                async () => {
                  this.loaderSvc.addRequest('general');
                  this.showCalendar = false;
                  await this.getSaleEventDetail(this.seSvc.currentSaleEvent.value?.saleEventCode!, true);
                }, 'modal', true, undefined, undefined, false, false, false, false)
            })
          }
        }
      )
    }
  }
}
